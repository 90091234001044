import { cloneDeep } from 'lodash-es'

export default function (text) {
  const { locale } = useI18n()
  const mySchema = cloneDeep(RichTextSchema)

  mySchema.marks.link = (node) => {
    const attrs = { ...node.attrs }
    const { linktype = 'url' } = node.attrs

    if (linktype === 'story') {
      attrs.href = `/${locale.value}/${formatSbPath(attrs.href, locale.value)}`
    }

    if (linktype === 'email') {
      attrs.href = `mailto:${attrs.href}`
    }

    if (attrs.anchor) {
      attrs.href = `${attrs.href}#${attrs.anchor}`
      delete attrs.anchor
    }

    return {
      tag: [
        {
          tag: 'a',
          attrs
        }
      ]
    }
  }

  return renderRichText(text, {
    schema: mySchema
  })
}
